import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import BrandImg from 'images/strike-brand.png';
import Hero from 'components/Hero';

import { Articles } from 'components/Pages/Legal/Sections';

const Legal = () => {
  return (
    <Layout>
      <SEO
        title="Legal"
        description="Strike legal"
        canonical="/legal"
        metaImage={BrandImg}
      />
      <Hero title="Legal" />
      <Articles />
    </Layout>
  );
};

export default Legal;
